import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Timestamp } from 'rxjs';
import { UploadFile } from 'ng-zorro-antd';

// ################################################
// INTERFACES
export interface UserLogin {
  password: string;
  logged: boolean;
}
export interface Result {
  result: boolean;
  data: any;
  message: string;
  value: number;
}
export interface Campagna {
  id: number;
  titolo: string;
  attiva: boolean;
}
export interface Categoria {
  id: number;
  titolo: string;
  colore: string;
}
export interface Premio {
  id: number;
  id_campagna: number;
  id_categoria: number;
  codice: string;
  titolo: string;
  punti: number;
  punti_contributo: number;
  contributo: number;
  immagine: string;
  ean: string;
  ean_contributo: string;
  attivo: boolean;
  prenotabile: boolean;
}
export interface Slide {
  id: number;
  titolo: string;
  immagine: string;
  attiva: boolean;
}
// ##############################################
// CLASS
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public userLogin: UserLogin;

  public nodeServer = 'https://ibapp.iperbattocchio.it/';
  // public nodeServer = 'http://localhost:8080/';

  public imgHost = 'https://ibcard.iperbattocchio.it/app/premi/';
  // public imgHost = 'http://ibcard.iperbattocchio.it/app/premi-test/';

  public advHost = 'https://ibcard.iperbattocchio.it/app/slide/';
  // public advHost = 'http://ibcard.iperbattocchio.it/app/slide-test/';

  public menuState = 0;
  public homeTitle = '';

  public normalTime: number = 10000;
  public fastTime: number = 3000;

  // ################################################
  // CONSTRUCTOR
  constructor(
    private http: HttpClient
  ) { }

  // ################################################
  // LOGIN
  public login(): Observable<Result> {
    return new Observable((observer) => {
      this.http.post<Result>(this.nodeServer + 'admin/login', this.userLogin).subscribe((res: Result) => {
        if (res.result === true) {
          this.userLogin = res.data;
          sessionStorage.setItem('ibadmin', JSON.stringify(this.userLogin));
          observer.next(res);
        } else {
          sessionStorage.setItem('ibadmin', null);
          observer.next(res);
        }
        observer.complete();
      }, (error: HttpErrorResponse) => {
        const res: Result = {
          result: null,
          message: error.statusText
        } as Result;
        observer.next(res);
        observer.complete();
      });
    });
  }
  // ################################################
  // LOGOUT
  public logout() {
    this.userLogin = {} as UserLogin;
    this.menuState = 0;
    sessionStorage.setItem('ibadmin', JSON.stringify(this.userLogin));
  }
  // ################################################
  // ASK ID
  public loginUtente(code: string): Observable<Result> {
    // tslint:disable-next-line:object-literal-key-quotes
    return this.http.post<Result>(this.nodeServer + 'loginUtente', { 'code': code });
  }
  // ################################################
  // MY SQL
  public mysql(sql: string): Observable<Result> {
    const obj = {
      query: sql
    };
    return this.http.post<Result>(this.nodeServer + 'admin/mysql', obj);
  }
  // ################################################
  // DELETE TILE
  public deleteFile(filename: string): Observable<Result> {
    const obj = {
      file: filename
    };
    return this.http.post<Result>(this.nodeServer + 'admin/deletefile', obj);
  }

}
